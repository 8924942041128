// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-agb-js": () => import("./../src/templates/agb.js" /* webpackChunkName: "component---src-templates-agb-js" */),
  "component---src-templates-angebot-js": () => import("./../src/templates/angebot.js" /* webpackChunkName: "component---src-templates-angebot-js" */),
  "component---src-templates-event-js": () => import("./../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-info-js": () => import("./../src/templates/info.js" /* webpackChunkName: "component---src-templates-info-js" */),
  "component---src-templates-kontakt-js": () => import("./../src/templates/kontakt.js" /* webpackChunkName: "component---src-templates-kontakt-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../src/templates/privacy_policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-specials-js": () => import("./../src/templates/specials.js" /* webpackChunkName: "component---src-templates-specials-js" */),
  "component---src-templates-success-js": () => import("./../src/templates/success.js" /* webpackChunkName: "component---src-templates-success-js" */)
}

